<template>
  <MyOrdersCard footer-class="pb-xs" :to="`/my-orders/single-order/${orderId}`">
    <template #header>
      <LotteryLogo v-bind="{ licensedTerritory, lotteryId }" />
    </template>

    <template #header-end>
      <slot name="header" />
    </template>

    <template #list>
      <IconListItemId :id="orderId" />

      <MyOrdersListItemPlay
        v-bind="{ lotteryId, numberOfPlays }"
        :add-ons="selectedAddOns"
      />

      <IconListItemDate
        v-bind="{ date }"
        :label="$t('myorders.listitem.date.label')"
      />
    </template>

    <template #replay-button>
      <MyOrdersReplayButton
        v-if="showReplayButton"
        v-bind="{ lotteryId, playSlipOrderId: id }"
        class="py-none px-xl"
        size="small"
        @click.stop.prevent
      />
    </template>

    <template #cta>
      {{ $t('myorders.card.singleorder.cta') }}
    </template>
  </MyOrdersCard>
</template>

<script lang="ts" setup>
import {
  type PlaySlipOrder,
  PlaySlipOrderStatus,
} from '~/@types/generated/backend/graphql-schema-types'

type MyOrdersSingleOrderCardProps = {
  date: Date | string
  id: PlaySlipOrder['id']
  licensedTerritory: PlaySlipOrder['licensedTerritory']
  lotteryId: PlaySlipOrder['lotteryId']
  numberOfPlays: PlaySlipOrder['numberOfPlays']
  orderId: PlaySlipOrder['orderId']
  selectedAddOns: PlaySlipOrder['selectedAddOns']
  showReplay?: boolean
  status: PlaySlipOrder['status']
}

const props = defineProps<MyOrdersSingleOrderCardProps>()

const activeLicensedTerritory = useLicensedTerritory({ raw: true })

const showReplayButton = computed(() => {
  const isSameLicensedTerritory =
    activeLicensedTerritory === props.licensedTerritory

  const isOrderClosed = props.status === PlaySlipOrderStatus.Closed

  return props.showReplay && isSameLicensedTerritory && isOrderClosed
})
</script>
