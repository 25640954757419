<template>
  <MyOrdersTab v-bind="{ fetchActive, fetchPast }" type="single-order">
    <template #no-results>
      <MyOrdersNoResults variant="single-order" />
    </template>

    <template #active="{ result }">
      <li
        v-for="singleOrder in getSingleOrders<ActivePlaySlipOrder>(result)"
        :key="singleOrder.orderId"
        data-containerid="single_orders_list_active"
      >
        <MyOrdersSingleOrderCardActive :data="singleOrder" />
      </li>
    </template>

    <template #past="{ result }">
      <li
        v-for="singleOrder in getSingleOrders<ClosedPlaySlipOrder>(result)"
        :key="singleOrder.orderId"
        data-containerid="single_orders_list_past"
      >
        <MyOrdersSingleOrderCardPast :data="singleOrder" />
      </li>
    </template>
  </MyOrdersTab>
</template>

<script lang="ts" setup>
import {
  type ActivePlaySlipOrder,
  type ClosedPlaySlipOrder,
  type PlaySlipOrdersQuery,
  PlaySlipOrderStatus,
} from '~/@types/generated/backend/graphql-schema-types'

const ACTIVE_STATUS = [
  PlaySlipOrderStatus.Ordered,
  PlaySlipOrderStatus.Purchased,
]
const PAST_STATUS = [PlaySlipOrderStatus.Closed, PlaySlipOrderStatus.Cancelled]

const fetchActive = () =>
  useSingleOrders({
    limit: 20,
    status: ACTIVE_STATUS,
  })

const fetchPast = () =>
  useSingleOrders({
    limit: 3,
    status: PAST_STATUS,
  })

const getSingleOrders = <T extends ActivePlaySlipOrder | ClosedPlaySlipOrder>(
  result: PlaySlipOrdersQuery,
) => result.playSlipOrders.playSlipOrders as T[]
</script>
