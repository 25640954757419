import type { OperationVariables } from '@apollo/client'

import type {
  PlaySlipOrdersQuery,
  PlaySlipOrderStatus,
} from '~/@types/generated/backend/graphql-schema-types'

type Parameters = {
  limit: number
  status: PlaySlipOrderStatus[]
}

type UpdateQueryOptions = {
  fetchMoreResult?: PlaySlipOrdersQuery
  variables?: OperationVariables
}

const updateQuery = (
  previousResult: PlaySlipOrdersQuery,
  { fetchMoreResult, variables }: UpdateQueryOptions,
): PlaySlipOrdersQuery => {
  if (!fetchMoreResult) return previousResult
  const { count, playSlipOrders } = fetchMoreResult.playSlipOrders

  if (
    variables?.limit === previousResult.playSlipOrders.playSlipOrders.length &&
    variables.offset === 0
  ) {
    return fetchMoreResult
  }

  return {
    playSlipOrders: {
      ...previousResult.playSlipOrders,
      count,
      playSlipOrders: [
        ...previousResult.playSlipOrders.playSlipOrders,
        ...playSlipOrders,
      ],
    },
  }
}

export const useSingleOrders = ({ limit, status }: Parameters) =>
  useFetchMoreWrapper(
    () =>
      usePlaySlipOrdersQuery(
        {
          filter: {
            status,
            subscription: false,
          },
          limit,
          offset: 0,
        },
        { fetchPolicy: 'cache-first' },
      ),
    updateQuery,
    (result) => result?.playSlipOrders.playSlipOrders.length,
    (result) => result?.playSlipOrders.count,
    'myorders.detail.playslip.error',
  )
